@media only screen and (max-width: 768px) {

    html,
    body {
        width: 100%;
        /* overflow-x: hidden; */
    }

    #home #timer {
        margin-top: -40%;
    }

    #home #info h3 {
        font-weight: 80;
        border-bottom: thin solid var(--font-color2);
        font-size: 3vw;
    }

    #home #info h5 {
        font-weight: 100;
        font-size: 3vw;
    }

    #about {
        height: 60vh;
        background-position: center 0%;
    }

    #about_info {
        font-size: 3vw;
        letter-spacing: 1cap;
        color: white;

    }

    #about_filter {
        background-color: rgba(0, 0, 0, 0.444);
    }

    #question {
        height: 40vh;
        background-color: var(--font-color1);
    }

    .qr_content {
        /* font-family: "Playwrite BE VLG", cursive; */
        font-weight: lighter;
        font-size: 60%;
        /* font-style: italic; */

    }


    #prog p {
        font-size: 150%;
    }

    #qu_tittle p {
        font-size: 150%;
    }

    .qr_tittle {
        font-size: 80%;
    }
}

@supports (-webkit-touch-callout: none) {

    /* CSS specific to iOS devices */
    body {
        background: url(../img/20240627_200907.jpg) no-repeat scroll;
        background-size: cover;
        background-position: center 20%;
        /* min-height: ; */
    }


    #prog p {
        font-size: 150%;
    }

    #qu_tittle p {
        font-size: 150%;
    }

    .qr_tittle {
        font-size: 80%;
    }

    #question {
        /* height: 40vh; */
        background-color: var(--font-color1);
        /* overflow: auto; */
        display: block;
    }

    /* #about {
        height: 60vh;
        background-position: center 0%;
    }
    #question {
        height: 50vh;
        background-color: var(--font-color1);
    } */
}