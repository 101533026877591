@import url(var.css);
@import url(module.font.css);

/* html {
    scroll-behavior: auto;
} */

/* * {
    border: 1px solid red !important;
} */

body {
    height: 100vh;
    width: 100%;
    background: url(../img/20240627_200907.jpg) no-repeat fixed;
    background-size: cover;
    background-position: center 60%;
    /* min-height: ; */
}

section {
    height: 100vh;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

#home {
    width: 50vw;
    margin-left: 50vw;
    background-color: #00000069;
    padding-left: 5%;
}

#home #timer {
    text-align: center;
    font-weight: lighter;
}

#home #timer .tm-cont {
    /* padding-bottom: 5%; */
    width: 10%;
    font-size: 1vw;
    color: var(--font-color2);
    /* background-color: blue; */
}

#home #timer .tm-cont p {
    padding-top: 20%;
    margin: 0;
    font-size: 3vw;
    font-weight: 200;
    color: var(--font-color2);
    /* background-color: aqua; */
}

#home #timer .bb {
    border-right: thin solid var(--font-color2);
}

#home #lela {
    margin-left: -12%;
    /* border-bottom: medium solid var(--font-color1);
    border-top: medium solid var(--font-color1); */
}

#home #lela p {
    font-size: 6vw;
    color: var(--font-color1);
    line-height: 110%;
    font-family: "Cormorant Garamond";
    /* font-weight: lighter;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif; */
}

#home #info {
    text-align: center;
    color: var(--font-color2);
    letter-spacing: 0.8cap;
}

#home #info h3 {
    font-weight: 80;
    border-bottom: thin solid var(--font-color2);
}

#home #info h5 {
    font-weight: 100;
}

.spacer1 {
    height: 10%;
}

.spacer2 {
    height: 5%;
}

.spacer3 {
    height: 25%;
}

/* .ta_right {
    text-align: right;
}

.ta_left {
    text-align: left;
} */

#about {
    /* background-color: var(--color_5); */
    height: 75vh;
    color: white;
    text-align: center;
    font-family: var(--font_1);
    flex-direction: row;
    font-weight: 200;
    background: url(../img/20240627_2002404.jpg) no-repeat;
    background-size: cover;
    background-position: center 9%;
}

#about_filter {
    /* background-color: rgba(0, 0, 0, 0.202); */
}

#prog p {
    color: white;
    font-size: 200%;
    letter-spacing: 1cap;
}

.prog_left {
    text-align: right;
}

.prog_right {
    text-align: left;
}


#about_info {
    font-size: 1.2vw;
    letter-spacing: 1cap;
}

.bb {
    border-right: thin solid var(--font-color1);
}

#question {
    height: 25vh;
    background-color: var(--color_5);
    /* background-color: wheat; */
    text-align: center;
    color: black;
    flex-direction: row;
}

/* #question .spacer2 {
    border-right: thin solid black;
} */

#question .bb {
    border-right: thin solid black;
}

#qu_tittle p {
    font-family: var(--font_1);
    font-weight: 200;
    font-size: 200%;
    letter-spacing: 1cap;
}

#qr {
    text-align: center;
}

.qr_col {
    /* background-color: aquamarine; */
    align-items: center;
}

.qr_tittle {
    /* font-family: "Playwrite BE VLG", cursive; */
    font-weight: lighter;
    font-style: oblique;
}

.qr_content {
    /* font-family: "Playwrite BE VLG", cursive; */
    font-weight: lighter;
    font-size: 80%;
    /* font-style: italic; */

}

#carousel {
    height: 100vh;
    background-color: var(--color_5);
}

/* #carousel #tttt {
    background-color: aqua;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
} */

.carousel-item {
    text-align: center;
}

#contact {
    height: 30vh;
    padding: 50px 0;
    background-color: var(--color_5);
}

#contact_title p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

#contact_info {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.contact_col {
    text-align: center;
}

.contact_tittle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.contact_content {
    font-size: 16px;
    color: #555;
}