@import url(var.css);
@import url(module.font.css);

body {
  /* height: 80vh; */
  /* padding-top: 15vh; */
}

/* Container styling */
#RSVPcontainer {

  height: 80vh;
  padding: 2rem 1.5rem;
  /* Adjusted padding */
  background-color: rgba(0, 0, 0, 0.2);
  /* Light overlay for subtle background */
  border-radius: 12px;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Softer shadow */
  margin: 0vh auto;
  margin-top: 15vh;
  /* margin-bottom: -30vh; */
  /* Centered container with margin */
  width: 50vw;
  /* Adjusted max-width */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Light border */
}

/* Form Title */
.RSVP-form h1 {
  font-family: "Cormorant Garamond";
  /* Matching font family */
  font-weight: 6rem;
  font-size: 400%;
  /* Larger title font size */
  color: white;
  /* Soft, muted pink */
  margin-bottom: 10vh;
  /* Space below the title */
  text-align: right;
  line-height: 110%;
  letter-spacing: 1cap;
  margin-top: -5vh;
  /* Centered title */
}

/* Form Group */
.form-group {
  margin-bottom: 1.5rem;
  /* Space between form groups */
}

/* Floating Labels */
.form-group .form-control {
  padding: 1.25rem 0.75rem 0.5rem;
  /* Adjusted padding */
  font-size: 1rem;
  /* Slightly larger font size */
  height: auto;
  border-radius: 8px;
  /* Rounded corners */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Light gray border */
}

.form-group .form-label {
  font-size: 1rem;
  /* Slightly larger font size */
  color: #a17c8c;
  /* Matching label color */
}

.floating-label label {
  top: 0.75rem;
}

/* Input Group */
.input-group .form-control {
  border-radius: 8px;
  /* Rounded corners */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Light gray border */
}

/* Button */
button[type="submit"] {
  background-color: #d3aab0;
  /* Soft blush pink */
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  /* Button padding */
  font-size: 1.1rem;
  /* Font size */
  font-weight: 600;
  border-radius: 8px;
  /* Rounded corners */
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1.5rem;
  /* Margin above the button */
}

button[type="submit"]:hover {
  background-color: #c68b8b;
  /* Slightly darker blush pink on hover */
  transform: translateY(-2px);
  /* Subtle lift effect */
}

/* Input Group Spacing */
.input-group {
  margin-bottom: 1.5rem;
  /* Spacing between input groups */
}

/* Form Check */
.form-check {
  margin-bottom: 1.5rem;
  /* Spacing below checkboxes */
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.form-check-input {
  /* margin-right: 0.75rem; */
  /* Space between checkbox and label */
  width: 1.5rem;
  /* Checkbox size */
  height: 1.5rem;
  /* Checkbox size */
}

.form-check-label {
  font-size: 1rem;
  /* Font size */
  color: white;
  /* Matching label color */
}

/* Form Select */
.form-select {
  padding: 1.25rem 0.75rem;
  /* Padding inside select box */
  font-size: 1rem;
  /* Font size */
  height: auto;
  border-radius: 8px;
  /* Rounded corners */
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Light gray border */
}

/* Error Message */
.alert-danger {
  font-size: 1rem;
  /* Font size */
  font-weight: 500;
  color: #721c24;
  background-color: #f8d7da;
  /* Light pink background for errors */
  border-color: #f5c6cb;
  /* Light pink border for errors */
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1.5rem;
  /* Margin above the error message */
  margin-bottom: 1.5rem;
  /* Margin below the error message */
  text-align: center;
  /* Centered text */
}

/* Responsive Design */
@media (max-width: 768px) {
  #RSVPcontainer {
    padding: 2rem 1rem;
    width: 80vw;
    /* Adjusted padding */
  }

  .RSVP-form h1 {
    font-size: 2rem;
    /* Adjusted font size */
    margin-bottom: 1rem;
    /* Adjusted margin */
  }

  .form-group .form-control,
  .form-select {
    font-size: 0.9rem;
    /* Font size adjustment */
  }

  button[type="submit"] {
    font-size: 1rem;
    /* Font size adjustment */
    padding: 0.6rem 1.25rem;
    /* Padding adjustment */
  }
}