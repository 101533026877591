/* NavBar.module.css */
@import url(var.css);
@import url(module.font.css);

.navbar {
    /* font-family: 'Roboto', sans-serif; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    text-align: center;
    font-family: commuterssans-light;
    /* height: 5%; */
    width: 100vw;
    /* background-color: white; */
}

/* .img-responsive {
    border-left: -85px;
} */


/* .navbar-brand {
    font-family: AbsoluteBeautyScriptRegular;
    font-weight: 400;
    font-style: normal;
    font-size: 55px;
    letter-spacing: 0em;
} */


.navLink {
    /* color: #c9c1c1; */
    font-weight: 1500;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: color 0.3s, background-color 0.3s;
    text-decoration: none;
    border-radius: 5px;
    margin-left: 30px;
    margin-right: 30px;
    background-color: var(--font-color2);
}

.navLink:hover {
    background-color: rgba(0, 0, 0, 0.444);
}

.m-navbar-expand-lg {
    background-color: rgba(0, 0, 0, 0.444);
}

.navbar.scrolling-navbar.top-nav-collapse {
    background: #ff0000;
}

@media only screen and (max-width: 768px) {

    .navbar-NavBar_navLink__Ev7TO nav-link {
        background: red;
    }

}